import React, { useState, useEffect } from 'react'
import { Button, Navbar, Nav, Form, FormControl, Spinner } from 'react-bootstrap';
import { Route, NavLink, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'
import GQLFetch from './GQLFetch'

import Notfound from './notfound'
import Home from './home/home'
import ContactUs from './contactus/contactus'
import AboutUs from './aboutus/aboutus'
import ProductDetail from './productdet/productdet'
import Search from './search/search'
import Kategori from './kategori/kategori';

const Header = (props) => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [navColor, setnavColor] = useState('#007bff')
    const [navtextColor, setNavtextColor] = useState('#fff')
    const [namaWebsite, setnamaWebsite] = useState('Homan System')
    const [search, setSearch] = useState(null)
    const [redirectTo, setRedirectTo] = useState(null)
    const [kategori, setKategori] = useState([])
    const [navExpanded, setNavExpanded] = useState(false)
    const [dropdownExpanded, setDropdownExpanded] = useState(false)
    useEffect(() => {
        var render = true;
        const query = `
            {
				mainInfo {
                    namawebsite
					navColor
					navTextColor
                }
                listKategori{
					kodekategori
					namakategori
				  }
			}
        `;
        setIsLoading(true)
        GQLFetch(query).then(res => {
            if (render) {

                setnavColor(res.data.mainInfo.navColor)
                setNavtextColor(res.data.mainInfo.navTextColor)
                setnamaWebsite(res.data.mainInfo.namawebsite)
                setKategori(res.data.listKategori)
            }
        }).catch(err => {
            if (render) setError(error);
        }).finally(() => {
            setIsLoading(false)
        })
        return () => {
            render = false;
        };
    }, [error])


    const formSubmitHandler = (event) => {
        console.log(search);
        setRedirectTo(`/search/${search}`)
        // props.history.push("/product/23EN-SATP25-181211'")
        event.preventDefault();

    }

    const searchHandler = (val) => {
        setSearch(val.target.value);
    }

    const handleDropdown = () => {
        setDropdownExpanded(!dropdownExpanded)
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    else if (isLoading) {
        return <Spinner className="spinner" animation="border" />;
    } else {
        return (
            <Router>
                {redirectTo ? (<Redirect to={redirectTo} />) : (null)}
                <Navbar style={{ backgroundColor: navColor }} className="menuPaddingDesktop fixed-top" variant="dark" expand="lg" onToggle={setNavExpanded.bind(true)} expanded={navExpanded}>
                    <Navbar.Brand className="HideMobile" style={{ color: navtextColor }}>{namaWebsite}</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Form inline className="formMobile" onSubmit={formSubmitHandler}>
                        <FormControl type="text" placeholder="Search" onChange={searchHandler} className="mr-sm-2" />
                        <Button hidden type="submit" variant="outline-light">Search</Button>
                    </Form>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto" onClick={setNavExpanded.bind(this, false)}>
                            <NavLink className="nav-link" style={{ color: navtextColor }} to="/"  >Home</NavLink>
                            {/* <NavDropdown title="kategori" id="basic-nav-dropdown" style={{ color: 'red' }} >
                                {
                                    kategori.map(el => {
                                        return (
                                            <NavLink key={el.kodekategori} to={`/kategori/${el.kodekategori}`} className="dropdown-item" >{el.namakategori}</NavLink>
                                        )
                                    })
                                }
                            </NavDropdown> */}
                            <div className="dropdown nav-item">
                                <NavLink
                                    onClick={handleDropdown}
                                    className="dropdown-toggle nav-link"
                                    role="button" style={{ color: navtextColor }}
                                >
                                    kategori
                            </NavLink>
                                <div className={`dropdown-menu ${dropdownExpanded ? ' show' : ''}`}  aria-labelledby="">
                                    {
                                        kategori.map(el => {
                                            return (
                                                <NavLink key={el.kodekategori} to={`/kategori/${el.kodekategori}`} className="dropdown-item" onClick={handleDropdown}>{el.namakategori}</NavLink>
                                            )
                                        })
                                    }
                                </div>
                            </div>


                            <NavLink className="nav-link" style={{ color: navtextColor }} to="/aboutus"   >About Us</NavLink>
                            <NavLink className="nav-link" style={{ color: navtextColor }} to="/contactus"   >Contact Us</NavLink>
                        </Nav>
                        <Form className="HideMobile" inline onSubmit={formSubmitHandler}>
                            <FormControl type="text" placeholder="Search" onChange={searchHandler} className="mr-sm-2" />
                            <Button type="submit" variant="outline-light">Search</Button>
                        </Form>
                    </Navbar.Collapse>
                </Navbar>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/aboutus" component={AboutUs} />
                    <Route path="/contactus" component={ContactUs} />
                    <Route path="/product/:kodeinduk" component={ProductDetail} />
                    <Route path="/search/:hasilcari" component={Search} />
                    <Route path="/kategori/:kodekategori" component={Kategori} />
                    <Route component={Notfound} />
                </Switch>
            </Router>
        )
    }
}
export default Header
