import React, { useState, useEffect } from 'react'
import GQLFetch from '../GQLFetch'
import { Spinner } from 'react-bootstrap'
import useDocumentTitle from '@rehooks/document-title';

const AboutUs = (props) => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [aboutUs, setAboutUs] = useState({})
    useDocumentTitle( "About Us");
    useEffect(() => {
        const query = `
            {
				mainInfo {
					aboutus
				}
			}
        `;
        setIsLoading(true)
        GQLFetch(query).then(res => {
            setAboutUs(res.data.mainInfo.aboutus)
        }).catch(err => {
            setError(error);
        }).finally(() => {
            setIsLoading(false)
        })
    }, [])

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    else if (isLoading) {
        return <Spinner className="spinner" animation="border" />;
    } else {
        return (
                <div className="paddingCustom">
                    <div className="border-bottom">
                        <h2>About Us</h2>
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: aboutUs }}></p>
                </div> 
        )
    }
}

export default AboutUs