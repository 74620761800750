import React, { useState, useEffect } from 'react'
import '../home/home.css'
import { Button, Row, Col,Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import GQLFetch from '../GQLFetch'
import useDocumentTitle from '@rehooks/document-title';

const Home = (props) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false)
    const [mainInfo, setMainInfo] = useState({})
    const [product, setProduct] = useState([])
    const [hal, setHal] = useState(1);
    useDocumentTitle(mainInfo.namawebsite && `Welcome to ${mainInfo.namawebsite}` || `Welcome to my Online Shop`);

    useEffect(() => {
        var render = true;
        var queryMainInfo = `
            mainInfo{
            namawebsite
            homebanner
        }
        `;
        if (hal > 1) queryMainInfo = '';
        const query = `
        {
            listInduk(hal: ${hal}){
            kodeinduk
            namainduk
            gambar
          }
          ${queryMainInfo}
        }
        `;
        GQLFetch(query).then(res => {
            if (hal === 1 && render) setMainInfo(res.data.mainInfo);
            render && setProduct(product.concat(res.data.listInduk));
        }).catch(err => {
            render && setError(err)
        }).finally(() => {
            render && setIsLoaded(true);
        })
        return () => {
            render = false;
        };
    }, [hal])

    const ulangRefresh = () => {
        setHal(hal + 1);
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <Spinner className="spinner" animation="border" />;
    } else {
        return (
            <div>
            
           
                <div className="full-width-image">
                    <img src={mainInfo.homebanner} alt="Logo" />
                </div>
                <div className="menuPaddingDesktop">
                    <h1 className="text-center border-bottom">PRODUCT</h1>
                    <div className="productWrapper">
                        {
                            product.map(el => {
                                return (
                                        <Col key={el.kodeinduk} sm={4} xs={6} lg={3}>
                                            <div className="addPad">
                                                <Link to={`product/${el.kodeinduk}`}>
                                                    <div className="prodImage">
                                                        <span className="prodTable">
                                                            <span className="prodCell">
                                                                <img src={el.gambar} alt="Product" />
                                                            </span>
                                                        </span>
                                                    </div>
                                                </Link>
                                                <div className="caption">
                                                    <Link to={`product/${el.kodeinduk}`}>
                                                        <span>{el.namainduk}</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </Col>
                                )
                            })
                        }
                    </div>
                    {
                        product.length === 10 && (
                            <Row>
                                <Button onClick={ulangRefresh} variant="primary">Load More</Button>
                            </Row>
                        )
                    }
                </div>
            </div>

        )
    }
}

export default Home