import React from 'react'



const ContactUs = (props) => {
    return (
        <div className="paddingCustom">
            <div className="border-bottom">
                <h2>Contact Us</h2>
            </div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse sed pretium eros. Integer mattis aliquet nisl non pellentesque. Ut tincidunt magna sit amet vehicula ullamcorper. Nullam eu molestie neque. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Quisque eget pretium odio. Suspendisse fringilla libero et sem faucibus dignissim. Maecenas vestibulum ultricies velit eget tincidunt. Donec facilisis justo sed sollicitudin malesuada. Integer varius nulla sit amet ultrices tristique. Maecenas at sem scelerisque, posuere dolor sit amet, dignissim ligula. Maecenas placerat quam sed nibh tempor, quis elementum lectus sollicitudin. Nullam id turpis vel massa laoreet mollis. Donec iaculis lacus sed nibh molestie pellentesque. Phasellus luctus nibh at porttitor semper. Donec vel turpis metus. Sed non velit a justo vehicula maximus maximus et dolor. Donec quis ligula mauris. Morbi rhoncus condimentum ante quis dignissim. Nunc hendrerit nec enim vitae dignissim. Cras commodo accumsan mollis. Duis fermentum massa malesuada ligula iaculis, in laoreet est tristique. Vivamus mollis nibh eu metus faucibus faucibus. Nunc ornare diam vitae metus convallis, vel sagittis mauris lobortis. Pellentesque ultrices, sapien vel dignissim elementum, lectus est blandit orci, nec pretium leo nisl vitae est. Ut iaculis eros leo, quis convallis ante placerat sit amet. Phasellus commodo rhoncus ex. Nam porta risus erat, quis venenatis mauris blandit non. Cras vel condimentum felis, at sollicitudin quam. Integer at nunc ullamcorper, dignissim magna in, sollicitudin arcu. Suspendisse non scelerisque est. In nisi mauris, dignissim varius vulputate quis, pretium vel purus. Ut non ante ac arcu aliquet scelerisque in sed leo. Nam non condimentum arcu, eu lobortis est.</p>
        </div>
    )
}
export default ContactUs