import React from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './header.js'
import './index.css'
import './product.css'
import registerServiceWorker from './registerServiceWorker';


const routing = (
    <Header/>
)
ReactDOM.render(routing, document.getElementById('root'))
registerServiceWorker();