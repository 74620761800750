import React, { useState, useEffect } from 'react'
import { Row, Col, Table, Spinner, Button, Dropdown } from 'react-bootstrap'
import '../home/home.css'
import GQLFetch from '../GQLFetch'
import CurrencyFormat from 'react-currency-format';
import useDocumentTitle from '@rehooks/document-title';
const Gambar = (props) => {
    return (
        <img className="mx-auto d-block img-thumbnail" src={props.url} alt="Product Detail" />
    )
}

const ProductDetail = (props) => {
    const { params } = props.match
    const [error, setError] = useState(null);
    const [image, setimage] = useState([])
    const [deskripsi, setDeskripsi] = useState({})
    const [varian, setVarian] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [gambarPilih, setGambarPilih] = useState(null);
    const [limitGambar, setLimitGambar] = useState(12)
    const [hideBtn, sethideBtn] = useState(false)
    useDocumentTitle(deskripsi.namainduk || `Welcome to My Online Shop`);

    useEffect(() => {
        var render = true;
        const query = ` {
            induk(kodeinduk: "${params.kodeinduk}"){
                namainduk
                size
                berat
                kategori
                subkategori
                deskripsi
                kelengkapan
                subinduk{
                    namasubinduk
                    stokqc
                    harga
                }
                    listGambar{
                        url
                    }
                }
           } 
        `;
        setIsLoading(true);
        GQLFetch(query).then(res => {
            if (render) {
                setimage(res.data.induk.listGambar);
                setGambarPilih(res.data.induk.listGambar[0].url)
                setDeskripsi(res.data.induk);
                setVarian(res.data.induk.subinduk);
            }

        }).catch(err => {
            if (render) setError(err);
        }).finally(() => render && setIsLoading(false))
        return () => {
            render = false;
        };
    }, [params.kodeinduk])

    const jumlahGambar = image.length

    const pilihGambar = (idx) => {
        setGambarPilih(image[idx].url)
    }
    const tampilSemuaGambar = () => {
        setLimitGambar(jumlahGambar)
        sethideBtn(true)
    }
  
    const copyDeskripsi = () => {
        var id = document.getElementById("Deskripsi").getAttribute('id');
        var el = document.getElementById(id);
        var range = document.createRange();
        range.selectNodeContents(el);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        document.execCommand('copy');
        alert("Contents copied to clipboard.");
        return false;
    }


    if (error) {
        return <div>Error: {error.message}</div>;
    }
    else if (isLoading) {
        return <Spinner className="spinner" animation="border" />;
    } else {
        return (
            <div className="paddingCustom">
                <div className="row" >
                    <div className="col-12">
                        <div className="productCenter">
                            <Gambar url={gambarPilih} />
                        </div>
                    </div>
                </div>
                <div className="productWrapper pd10">
                    <Row>
                        {
                            image
                                .filter((listGambar, idx) => (idx < limitGambar))
                                .map((listGambar, idx) => {
                                    return (
                                        <Col key={idx.toString()} md={1} xs={3} className="padmin">
                                            <div className="addPad">
                                                <div className="prodImage">
                                                    <span className="prodTable-100">
                                                        <span className="prodCell-100">
                                                            <img src={listGambar.url} onClick={pilihGambar.bind(this, idx)} className="img-responsive mx-auto d-block img-thumbnail tangan" alt="" />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                        }
                    </Row>
                    {
                        !hideBtn && jumlahGambar > 12 && (
                            <Button variant="link" className="link-right" onClick={tampilSemuaGambar}>Tampil semua ({jumlahGambar} gambar)</Button>
                        )
                    }

                </div>
                <Row>
                    <Col xs={12} >
                        <h5>
                            {deskripsi.namainduk}
                        </h5><br />
                    </Col>
                    <Col xs={12} >
                        <h4 className="border-bottom">Deskripsi</h4>
                    </Col>
                    <Col xs={12}>
                        <div id="Deskripsi">
                            <span dangerouslySetInnerHTML={{ __html: deskripsi.deskripsi }}></span>
                            Ukuran: {deskripsi.size}<br />
                            Berat : {deskripsi.berat} <br />
                            Kategori : {deskripsi.subkategori} <br />
                            Kelengkapan : {deskripsi.kelengkapan} <br />
                        </div>
                        <button id="Deskripsi" onClick={copyDeskripsi}>  Copy Deskripsi</button>
                    </Col>
                    <Col xs={12} className="pdtop20">
                        <h4>Variant</h4>
                        <Table responsive striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Variant</th>
                                    <th>Stok</th>
                                    <th>Harga</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    varian.map((listVariant, idx) => {
                                        return (
                                            <tr key={idx.toString()}>
                                                <td>{listVariant.namasubinduk}</td>
                                                <td>{listVariant.stokqc}</td>
                                                <td>
                                                    <CurrencyFormat value={listVariant.harga} displayType={'text'} thousandSeparator={true} />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </Table>
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                Save Image
                        </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href={gambarPilih} download>Download Selected</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Download All</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>

            </div>
        )
    }

}

export default ProductDetail