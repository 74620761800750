const GQLFetch = (query) => {
    const myHeader = new Headers();
    myHeader.append("X-APP-TOKEN", "308");
    myHeader.append("Accept", "application/json");
    myHeader.append("Content-Type", "application/json");
    
    const myInit = {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify({
            query:query})
    }
    const myRequest = new Request("https://apibeta.sistem308.com/graphql/", myInit);return fetch(myRequest).then(res => res.json());
}
export default GQLFetch;